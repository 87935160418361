import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire Hotjar Expert Researcher`,
    text: "Best Hotjar Professionals",
    content: "Hire Hotjar researcher to understand your user behavior, discover usability problems, and provide solutions to enhance the overall user experience. ",
    list: [
      'Top Hotjar Experts',
      'Flexible Hiring Options',
      'Time-Zone Friendly',
    ]
  };
  const ourData = {
    title: 'Clients Recommend Our Hotjar Researcher',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Get the Best <span class='h1-span'>Hotjar Experts for Hire</span>",
    content: [
      {
        title: "210+ Clients Served",
        text: "We have worked with 210+ clients across diverse industries, enabling us to offer versatile and tailored strategies for your unique goals.",
      },
      {
        title: "1k+  User Interviews",
        text: "Having conducted 1000+ interviews, our experienced team identifies user insights and trends, assisting in data-driven decision-making.",
      },
      {
        title: "20k+ Recordings Observed",
        text: "With experience observing 20k+ recordings, our hotjar experts for hire identify user patterns and behaviors, enabling continuous refinement.",
      },
      {
        title: "25+ Research Experts",
        text: "With a team of 25+ seasoned researchers, our team brings diverse perspectives to every project, ensuring innovative solutions tailored to your user’s needs.",
      },
    ],
  };
  const commonContent1 = {
    title: "Hire Hotjar Experts <span class='h1-span'>To Optimise Your Product</span>",
    content: [
      {
        title: "Discover Insights others miss",
        text: "Our hotjar researchers delve deep to uncover hidden insights and leverage Hotjar’s data to drive improvements in your user experience.",
      },
      {
        title: "Uncover UX issues, fast",
        text: "Our experts promptly identify UX shortcomings and employ rapid analysis techniques to enable timely resolutions & continuous web enhancements.",
      },
      {
        title: "Spot Opportunities",
        text: "Our Hotjar professionals pinpoint the areas of enhancement and apply continuous improvement strategies to improve user satisfaction.",
      },
      {
        title: "Connect with user 1:1",
        text: "Our researchers conduct personalized interactions with your users to gain insights and offer tailored solutions to meet their preferences.",
      },
      {
        title: "Validate your ideas with user data",
        text: "Our experts meticulously analyse user data and make decisions based on concrete evidence to ensure it align with your requirements.",
      },
      {
        title: "Give ideas & solutions to problems",
        text: "Our hotjar researchers tackle complex challenges with their expertise and bring solutions that deliver tangible results, fostering innovation.",
      },
    ],
  };
  const Questions = [
    "How do I hire Hotjar researcher from Octet?",
    "Can I hire Hotjar Expert for a short-term project?",
    "How much does it cost to hire a Hotjar expert for hire from Octet?"
  ];
  const faqData = [
    {
      para: [
        `Hiring a Hotjar Expert from Octet is quite simple and involves 3 easy steps:`,
      ],
      list: [
        `<span><h4 class="inline">Share your Requirement:</h4></span> Share your business needs and project details with us, outlining your objectives and desired outcomes for user insights.`,
        `<span><h4 class="inline">Interview Your Hotjar Expert:</h4></span> You can then interview the Hotjar experts provided by us, assessing their skills and compatibility with your project requirements.`,
        `<span><h4 class="inline">Onboard and Start:</h4></span> Onboard the hired Hotjar expert and initiate collaboration to optimize your research efforts effectively.`
      ]
    },
    {
      para: [
        `Yes, you can hire hotjar expert researcher for short-term projects! At Octet, we offer flexible hiring options tailored to your project's duration.`,
        `Whether it's a brief assignment or a long-term project, you can hire Hotjar experts who are ready to adapt to your specific needs.`
      ],
    },
    {
      para: [
        `The cost to hire best hotjar professional from Octet varies depending on factors such as the scope of the project, the expertise required, and the duration of engagement. `,
        `We would request you to <a target="_blank" title="Contact Us" rel="noreferrer" href="/contact-us/">contact us</a> to discuss your specific needs and pricing quotations. `,
      ],
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
           {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How do I hire Hotjar researcher from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring a Hotjar Expert from Octet is quite simple and involves 3 easy steps:
          Share your Requirement:
          Share your business needs and project details with us, outlining your objectives and desired outcomes for user insights.
          Interview Your Hotjar Expert:
          You can then interview the Hotjar experts provided by us, assessing their skills and compatibility with your project requirements.
          Onboard and Start:
          Onboard the hired Hotjar expert and initiate collaboration to optimize your research efforts effectively."
              }
            },{
              "@type": "Question",
              "name": "Can I hire Hotjar Expert for a short-term project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, you can hire hotjar expert researcher for short-term projects! At Octet, we offer flexible hiring options tailored to your project's duration.
          
          Whether it's a brief assignment or a long-term project, you can hire Hotjar experts who are ready to adapt to your specific needs."
              }
            },{
              "@type": "Question",
              "name": "How much does it cost to hire a Hotjar expert for hire from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The cost to hire best hotjar professional from Octet varies depending on factors such as the scope of the project, the expertise required, and the duration of engagement.
          
          We would request you to contact us to discuss your specific needs and pricing quotations."
              }
            }]
          }         
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Hotjar Research Analyst" hotjarHire={true}>
        <div>
          <HireBanner data={banner} hire pageName="Hire Hotjar Research Analyst" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <OurWorkslef data={ourData} hire />
          <CommonServiceComponent data={commonContent} mode={"dark"} hire />
          <CommonServiceComponent data={commonContent1} mode={"light"} hire />
          <Faq grey section Questions={Questions} faqData={faqData} des={'Read about the common queries before hiring Hotjar expert researcher to optimise and enhance your user insights effectively.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Hire Top Hotjar Experts and Researcher - Try for Free"
    description="Hire Hotjar experts to transform user data into actionable insight. Our Hotjar researchers analyse user behaviour & discover hidden opportunities. Hire Now!"
  />
);